import { MatInputModule } from '@angular/material/input';
import { ApiService } from "./_services/api.service";
import { QuestionnaireService } from "./_services/questionnaire.service";
import { ActiveSession } from "./_guards/active-session.guard";
import { AuthService } from "src/app/_services/auth.service";
import { AppRoutingModule } from "./app.routing";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { LoginModule } from "./login/login.module";
import { LoggedInGuard } from "./_guards/logged-in.guard";
import { HomeModule } from "./home/home.module";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AuthtokenService } from "./_services/authtoken.service";
import { ObjectDetailsService } from "./_services/object.details.service";
import { DataSharingService } from "./_services/data.sharing.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    HttpClientModule,
    CommonModule,
    RouterModule,

    AppRoutingModule,
    LoginModule,
    HomeModule,
  ],
  providers: [
    LoggedInGuard,
    ActiveSession,
    AuthService,
    AuthtokenService,
    ObjectDetailsService,
    DataSharingService,
    QuestionnaireService,
    ApiService,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
