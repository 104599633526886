import { AccountLogoComponent } from './account-logo.component';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule
        ],
    declarations: [
        AccountLogoComponent,
    ],
    exports: [
        AccountLogoComponent,
    ],
}) export class AccountLogoModule {

}
