import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { from } from 'rxjs';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const myFirstPromise = new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('accessToken');
      if (token == null || token === 'undefined') {
        reject('no jwt');
      } else {
        resolve(token);
      }
    });

    return from(myFirstPromise.then(() => {
      return true;
    }).catch(() => {
      this.router.navigate(['/login']);
      return false;
    }));
  }
}
