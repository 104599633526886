import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-date-range-selector',
    templateUrl: './date-range-selector.component.html',
    styleUrls: [
        './date-range-selector.component.css'
    ]
}) export class DateRangeSelectorComponent implements OnInit {
    @Output() selectionMade = new EventEmitter<any>();
    @Input() since: any;

    @Input() sDate: Date;
    @Input() eDate: Date;

    fromDate: UntypedFormControl = new UntypedFormControl();
    toDate: UntypedFormControl = new UntypedFormControl();
    tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

    minAnchorDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));
    yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    today = new Date();

    constructor(
        private snackbar: MatSnackBar
    ) { }

    ngOnInit() {
        if (this.since && this.since === '1d') {
            this.fromDate.setValue(this.sDate ? this.sDate : this.yesterday);
        } else {
            this.fromDate.setValue(this.sDate ? this.sDate : this.sevenDaysAgo);
        }
        this.toDate.setValue(this.eDate ? this.eDate : this.tomorrow);
        this.fromDate.valueChanges.subscribe(res => {
            this.selectionMade.emit({ 'start': this.convertDate(res) });
        });
        this.toDate.valueChanges.subscribe(res => {
            this.selectionMade.emit({ 'end': this.convertDate(res) });
        })
        setTimeout(() => this.filterResults(), 1000);
    }

    filterResults() {
        const date1 = new Date(this.fromDate.value);
        const date2 = new Date(this.toDate.value);
        if (date1 && date2) {
            if (date2 > date1) {
                this.selectionMade.emit({
                    'start': this.convertDate(this.sDate ? this.sDate : date1),
                    'end': this.convertDate(this.eDate ? this.eDate : date2)
                });
            } else {
                this.snackbar.open('invalid date range', 'ERROR', { duration: 2000 });
            }
        } else {
            this.snackbar.open('invalid date range', 'ERROR', { duration: 2000 });
        }
    }

    convertDate(data: Date) {
        return new Date(data.getTime() - (data.getTimezoneOffset() * 60000)).toJSON();
    }
}
