import { ApiService } from 'src/app/_services/api.service';
import { AuthtokenService } from './../_services/authtoken.service';
import { AuthService } from './../_services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login-component',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.css',
    ]
}) export class LoginComponent {
    loading: boolean;
    attemptsWarning: boolean;
    newPasswordRequired = false;

    token: any;
    user: any;

    loginForm: UntypedFormGroup;
    pattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');


    constructor(
        private formBuilder: UntypedFormBuilder,
        private snackbar: MatSnackBar,
        private authService: AuthService,
        private tokenService: AuthtokenService,
        private apiService: ApiService,
        private router: Router
    ) {
        this.initForm();
        this.loading = false;
        this.attemptsWarning = false;
    }

    initForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    login() {
        if (this.loginForm.valid) {
            this.loading = true;
            if (this.newPasswordRequired) {
                // todo: future implementation for forgot password
            } else {
                this.normalLogin();
            }
        } else {
            this.snackbar.open('Email and|or password invalid', 'ERROR');
        }
    }

    normalLogin() {
        this.loading = true;
        this.authService.signIn({ username: this.loginForm.get('email').value, password: this.loginForm.get('password').value })
            .subscribe((res: any) => {
                sessionStorage.setItem('accessToken', res.accessToken);
                sessionStorage.setItem('tokenType', res.tokenType);
                this.tokenService.setToken(res.accessToken);
                this.token = res.accessToken;
                this.loading = false;
                this.getUserApps();
            }, () => {
                this.loading = false;
                const snackbarRef = this.snackbar.open('error retreiving your login', 'ERROR', { duration: 2000 });
                snackbarRef.afterDismissed().subscribe(info => {
                    if(!this.attemptsWarning){
                        this.snackbar.open('only 3 attempts allowed', 'INFO', { duration: 2000 });
                        this.attemptsWarning = true;
                    }
                  });
            });
    }

    getUserApps() {
        this.authService.getApps(this.token).subscribe(res => {
            sessionStorage.setItem('apps', JSON.stringify(res));
            this.tokenService.setApps(res);
            this.loading = false;
            this.getAccountSettings();
            // this.router.navigate(['/home']);
        });
    }

    getAccountSettings() {
        this.apiService.genericGET(this.token, 'user-accounts/profile').subscribe(resp => {
            // store in sessionStorage

            sessionStorage.setItem('currency', resp.organization.organizationSettings.currency ? resp.organization.organizationSettings.currency : undefined);
            sessionStorage.setItem('vat', resp.organization.organizationSettings.vat ? resp.organization.organizationSettings.vat : undefined);
            sessionStorage.setItem('vatInclusive', resp.organization.organizationSettings.vatInclusive ? resp.organization.organizationSettings.vatInclusive : undefined);
            sessionStorage.setItem('salesPaymentEnabled', resp.organization.organizationSettings.salesPaymentEnabled ? resp.organization.organizationSettings.salesPaymentEnabled : undefined);
            this.router.navigate(['/home']);
        });
    }
}
