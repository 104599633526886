import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './_guards/logged-in.guard';

const MAIN_ROUTES: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard] },

  { path: 'leads', loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule), canActivate: [LoggedInGuard] },
  { path: 'businesses', loadChildren: () => import('./businesses/businesses.module').then(m => m.BusinessesModule), canActivate: [LoggedInGuard] },
  { path: 'dashboards', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule), canActivate: [LoggedInGuard] },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [LoggedInGuard] },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [LoggedInGuard] },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule), canActivate: [LoggedInGuard] },
  { path: 'warehousing', loadChildren: () => import('./warehousing/warehousing.module').then(m => m.WarehousingModule), canActivate: [LoggedInGuard] },
  { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule), canActivate: [LoggedInGuard] },
  { path: 'visits', loadChildren: () => import('./visits/visits.module').then(m => m.VisitsModule), canActivate: [LoggedInGuard] },

  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [LoggedInGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(MAIN_ROUTES, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
}) export class AppRoutingModule {

}

