

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


/**
 * Class for sharing data between unrelated components
 * Object details in this case is data to be diaplayed in a generic Details Display component
 */
@Injectable()
export class DataSharingService {
    private objectSource = new BehaviorSubject({});
    private metaSource = new BehaviorSubject({});
    private dataDefaultsSource = new BehaviorSubject({});
    private notificationsSource = new BehaviorSubject({});

    private accountSource = new BehaviorSubject('');
    private accountDataSource = new BehaviorSubject({});
    private userProfileDataSource = new BehaviorSubject({});


    currentObject = this.objectSource.asObservable();
    currentMeta = this.metaSource.asObservable();
    dataDefault = this.dataDefaultsSource.asObservable();
    currentNotification = this.notificationsSource.asObservable();

    currentAccount = this.accountSource.asObservable();
    currentAccountData = this.accountDataSource.asObservable();
    currentUserProfile = this.userProfileDataSource.asObservable();

    // empty constructor
    constructor() { }

    changeObject(object: any) {
        this.objectSource.next(object);
    }

    changeMeta(object: any) {
        this.metaSource.next(object);
    }

    changeData(object: any) {
        this.dataDefaultsSource.next(object);
    }

    changeNotification(object: any) {
        this.notificationsSource.next(object);
    }

    changeAccount(object: any) {
        this.accountSource.next(object);
    }

    changeAccountData(object: any) {
        this.accountDataSource.next(object);
    }
    
    changeUserProfile(object: any) {
        this.userProfileDataSource.next(object);
    }
}

