import { MatIconModule } from "@angular/material/icon";
import { AccountLogoModule } from "../account-logo/account-logo.module";
import { UserProfileModule } from "../../_modals/user_profile/user_profile.module";
import { MainSidebarComponent } from "./main-sidebar.component";
import { NgModule } from "@angular/core";

import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UserProfileModule,
    AccountLogoModule,
    MatIconModule,
  ],
  declarations: [MainSidebarComponent],
  exports: [MainSidebarComponent],
})
export class MainSidebarModule {}
