import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-user-profile-component',
    templateUrl: './user_profile.component.html',
    styleUrls: [
        './user_profile.component.css',
    ],
}) export class UserProfileComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<UserProfileComponent>,
    ) { console.log(data) }

    validateForm() {
        this.dialogRef.close(true);
    }
}
