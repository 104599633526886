import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConstants } from '../_constants/app.constants';
import { HttpHeaders, HttpClient, HttpRequest, HttpParams } from '@angular/common/http';



@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient,
    ) { }

    getGenericList(token: any, path: string, _params?: any): Observable<any> {
        return this.http.get(AppConstants.API_HOST_NAME + '/' + path + '/list', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    genericGET(token: any, path: string, _params?: any): Observable<any> {
        return this.http.get(AppConstants.API_HOST_NAME + '/' + path, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    genericPOSTOrPUT(action: string, token: any, path: string, data: any, _params?: any): Observable<any> {
        if (action === 'post') {
            return this.http.post(AppConstants.API_HOST_NAME + '/' + path,
                JSON.stringify(data), {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }), params: _params ? _params : {}
            });
        } else {
            return this.http.put(AppConstants.API_HOST_NAME + '/' + path,
                JSON.stringify(data), {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }), params: _params ? _params : {}
            });
        }
    }

    genericPUT(token: any, path: string, data: any, _params?: any): Observable<any> {
        return this.http.put(AppConstants.API_HOST_NAME + '/' + path,
            JSON.stringify(data), {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    genericPOST(token: any, path: string, data: any, _params?: any): Observable<any> {
        return this.http.post(AppConstants.API_HOST_NAME + '/' + path,
            JSON.stringify(data), {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }


    genericDELETE(token: any, path: string, _params?: any): Observable<any> {
        return this.http.delete(AppConstants.API_HOST_NAME + '/' + path, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    getGenericReport(token: any, path: string, _params?: any): Observable<any> {
        return this.http.get(AppConstants.API_HOST_NAME + '/' + path, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), responseType: 'blob' as 'json', params: _params
        });
    }

    genericUpload(url: string, file: any, contentType: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': contentType });
        const req = new HttpRequest(
            'PUT',
            url,
            file,
            {
                headers: headers,
                reportProgress: true
            });
        return this.http.request(req);
    }

    genericPOSTFile(url: string, file: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const req = new HttpRequest(
            'POST',
            url,
            file,
            {
                headers: headers,
                reportProgress: true
            });
        return this.http.request(req);
    }

    genericCloudDelete(url: string): Observable<any> {
        const req = new HttpRequest(
            'DELETE',
            url, {
            reportProgress: true
        });
        return this.http.request(req);
    }

    genericCloudGet(url: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const req = new HttpRequest('GET', url, {
            headers: headers,
            responseType: 'text',
            // reportProgress: true
        });
        return this.http.request(req);
    }

    uploadCsvFile(token: any, path: string, formData: any): Observable<any> {
        const req = new HttpRequest('POST', AppConstants.API_HOST_NAME + '/' + path, formData, {
            headers: new HttpHeaders({
                // 'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
                // 'Content-Type': 'text/csv'
            }),
            reportProgress: true
        });
        return this.http.request(req);
    }

    getOrderReport(token: string, id: string): Observable<any> {
        return this.http.post(AppConstants.API_HOST_NAME + '/orders/pdf-report/' + id, {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }), responseType: 'blob' as 'json'
        });
    }

    getSaleReport(token: string, id: string): Observable<any> {
        return this.http.post(AppConstants.API_HOST_NAME + '/sales/pdf-report/' + id, {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }), responseType: 'blob' as 'json'
        });
    }

}
