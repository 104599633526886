import { ApiService } from "src/app/_services/api.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class QuestionnaireService {
  path: string;
  custom_meta: any = {};
  constructor(private apiService: ApiService) { }

  postFormData(token: string, data: any, meta: any): Observable<any> {
    switch (meta.type) {
      case "option":
        this.path =
          meta.action === "create" ? "options/list" : "options/" + meta.data.id;
        break;
      case "option-set":
        this.path =
          meta.action === "create"
            ? "option-sets/list"
            : "options/" + meta.data.id;
        break;
      case "stageflow":
        this.path = "stage-flows/list";
        break;
      case "report-folder":
        this.path =
          meta.action === "create"
            ? "report-folders/list"
            : "report-folders/" + meta.data.id;
        break;
      case "custom-report":
        this.path =
          meta.action === "create"
            ? "custom-reports/list"
            : "custom-reports/" + meta.data.id;
        break;
      case "user-role":
        this.path =
          meta.action === "create"
            ? "user-roles/list"
            : "user-roles/" + meta.data.id;
        break;
      case "user-dashboard":
        this.path =
          meta.action === "create"
            ? "user-dashboards/list"
            : "user-dashboards/" + meta.data.id;
        break;
      case "dashboard-chart":
        this.path =
          meta.action === "create"
            ? "dashboard-charts/list"
            : "dashboard-charts/" + meta.data.id;
        break;
      case "business-type":
        this.path =
          meta.action === "create"
            ? "organization-business-types/list"
            : "organization-business-types/" + meta.data.id;
        break;
      case "region":
        this.path =
          meta.action === "create"
            ? "organization-regions/list"
            : "organization-regions/" + meta.data.id;
        break;
      case "user":
        this.path =
          meta.action === "create"
            ? "user-accounts/signup"
            : "user-accounts/" + meta.data.id;
        break;
      case "lead":
        this.path = meta.action === "create" ? "leads/list" : "leads/" + meta.data.id;
        break;
      case "lead-notes":
        this.path = "leads/notes";
        break;
      case "lead-reminder":
        this.path = meta.action === "create" ? "leads/reminder" : "leads/reminder/" + meta.data.id;
        break;
      case "business":
        this.path =
          meta.action === "create"
            ? "businesses/list"
            : "businesses/" + meta.data.id;
        break;
      case "warehouse":
        this.path =
          meta.action === "create"
            ? "warehouses/list"
            : "warehouses/" + meta.data.id;
        break;
      case "supplier":
        this.path =
          meta.action === "create"
            ? "suppliers/list"
            : "suppliers/" + meta.data.id;
        break;
      case "warehouse-store":
        this.path =
          meta.action === "create"
            ? "warehouse-stores/list"
            : "warehouse-stores/" + meta.data.id;
        break;
      case "warehouse-ingress":
        this.path =
          meta.action === "create"
            ? "warehouse-ingress/create"
            : "warehouse-ingress/" + meta.data.id;
        break;
      case "stock-update":
        this.path = "warehouse-stock/update/" + meta.data.id;
        break;
      case "business-stock-update":
        this.path = "business-stock/min-stock";
        break;
      case "division":
        this.path =
          meta.action === "create"
            ? "organization-divisions/list"
            : "organization-divisions/" + meta.data.id;
        break;
      case "product":
        this.path =
          meta.action === "create"
            ? "products/list"
            : "products/" + meta.data.id;
        break;
      case "activity":
        this.path =
          meta.action === "create"
            ? "activities/list"
            : "activities/" + meta.data.id;
        break;
      case "promotion":
        this.path =
          meta.action === "create"
            ? "promotions/list"
            : "promotions/" + meta.data.id;
        break;
      case "promotion-tier":
        this.path =
          meta.action === "create"
            ? "promotion-tiers/create"
            : "promotion-tiers/" + meta.data.id;
        break;
      case "bundle":
        this.path =
          meta.action === "create"
            ? "inventory/bundles/list"
            : "inventory/bundles/" + meta.data.id;
        break;
      case "assignee":
        this.path =
          meta.action === "create" ? "orders/list" : "orders/" + meta.data.id;
        break;
      case "order-status":
        this.path =
          meta.action === "create" ? "orders/list" : "orders/" + meta.data.id;
        break;
      case "delivery":
        this.path =
          meta.action === "create"
            ? "deliveries/list"
            : "deliveries/" + meta.data.id;
        break;
      case "task-template":
      case "task-template-product":
        this.path =
          meta.action === "create"
            ? "task-templates/list"
            : "task-templates/" + meta.data.id;
        break;
      case "task-template-item":
        this.path =
          meta.action === "create"
            ? "task-template-items/list"
            : "task-template-items/" + meta.data.id;
        break;
      case "task":
        this.path =
          meta.action === "create" ? "tasks/list" : "tasks/" + meta.data.id;
        break;
      case "user-target":
        this.path =
          meta.action === "create"
            ? "user-targets/list"
            : "user-targets/" + meta.data.id;
        break;
      case "delivery-date":
        this.path =
          meta.action === "create" ? "orders/list" : "orders/" + meta.data.id;
        break;
      case "taxcode":
        this.path =
          meta.action === "create"
            ? "tax-codes/list"
            : "tax-codes/" + meta.data.id;
        break;
      case "price-tier":
        this.path =
          meta.action === "create"
            ? "price-tiers/create"
            : "price-tiers/" + meta.data.id;
        break;
      case "asset-definition":
        this.path =
          meta.action === "create"
            ? "asset-definitions/list"
            : "asset-definitions/" + meta.data.id;
        break;
      case "asset-transfer":
        this.path = "asset-transfers/" + meta.data.id;
        break;
      case "asset-transfer-request":
        this.path = "asset-transfers/list";
        break;
      case "price-list":
        this.path = meta.action === "create" ? "price-lists/list" : "price-lists/" + meta.data.id;
        break;
      case "product-package":
        this.path = meta.action === "create" ? "product-packages/list" : "product-packages/" + meta.data.id;
        break;
      case "product-package-item":
        this.path = meta.action === "create" ? "product-packages/package-item" : "product-packages/package-item/" + meta.data.id;
        break;
    }

    return this.apiService.genericPOSTOrPUT(
      meta.action === "create" ? "post" : "put",
      token,
      this.path,
      data,
      this.custom_meta
    );
  }
}
