import { NgModule } from '@angular/core';
import { AppHeightDirective } from './appHeight.directive';

@NgModule({
  imports: [
  ],
  declarations: [
      AppHeightDirective
  ],
  exports: [
    AppHeightDirective,
  ]
}) export class AppHeightDirectiveModule {

}
