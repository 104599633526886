import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


/**
 * todo: implement refreshToken to renew jwt
 */
@Injectable()
export class AuthtokenService {
    private tokenSource;
    private appsSource;
    private currencySource;

    currentToken: any;
    currentApps: any;
    currentCurrency: any;

    public currency: string;

    constructor() {
        this.tokenSource = new BehaviorSubject(sessionStorage.getItem('accessToken'));
        this.currentToken = this.tokenSource.asObservable();

        this.appsSource = new BehaviorSubject(JSON.parse(sessionStorage.getItem('apps')));
        this.currentApps = this.appsSource.asObservable();

        this.currencySource = new BehaviorSubject('');
        this.currentCurrency = this.currencySource.asObservable();
    }

    setToken(data: any) {
        this.tokenSource.next(data);
    }

    setApps(data: any) {
        this.appsSource.next(data);
    }

    setCurrency(data: string) {
        this.currencySource.next(data);
        this.currency = data;
        sessionStorage.setItem('currency', data);
    }

}
