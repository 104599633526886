import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-account-logo-component',
    templateUrl: './account-logo.component.html',
    styleUrls: [
        './account-logo.component.css'
    ]
}) export class AccountLogoComponent implements OnChanges {
    imageData: any;
    @Input() url: string;

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) { }

    getImage(url: string) {
        if (url) {
            this.http.get(url, { responseType: 'blob' }).subscribe(result => {
                const unsafeImageUrl = URL.createObjectURL(result);
                this.imageData = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.url.currentValue) {
            this.getImage(changes.url.currentValue);
        }
    }

    createImageFromBlob(image: any) {
        this.imageData = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + this.encode(image));
    }

    encode(data) {
        const str = data.reduce(function (a, b) { return a + String.fromCharCode(b); }, '');
        return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
    }
}
