import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


/**
 * Class for sharing data between unrelated components
 * Object details in this case is data to be diaplayed in a generic Details Display component
 */
@Injectable()
export class ObjectDetailsService {
    private objectSource = new BehaviorSubject({});
    private metaSource = new BehaviorSubject({});

    currentObject = this.objectSource.asObservable();
    currentMeta = this.metaSource.asObservable();

    // empty constructor
    constructor() { }

    /**
     * Update object
     * @param object new object
     */
    changeObject(object: any) {
        this.objectSource.next(object);
    }

    /**
     * Update meta
     * @param object new meta
     */
     changeMeta(object: any) {
        this.metaSource.next(object);
    }
}
