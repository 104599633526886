import { GcpProfilePhotoComponent } from './gcp-profile-photo.component';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule
        ],
    declarations: [
        GcpProfilePhotoComponent,
    ],
    exports: [
        GcpProfilePhotoComponent,
    ],
}) export class GcpProfilePhotoModule {

}
