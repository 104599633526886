import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { AccountLogoModule } from "./../account-logo/account-logo.module";
import { UserProfileModule } from "./../../_modals/user_profile/user_profile.module";
import { MainToolbarComponent } from "./main-toolbar.component";
import { NgModule } from "@angular/core";

import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UserProfileModule,
    AccountLogoModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule
  ],
  declarations: [MainToolbarComponent],
  exports: [MainToolbarComponent],
})
export class MainToolbarModule {}
