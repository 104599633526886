import { MatPaginatorModule } from '@angular/material/paginator';
import { DateRangeSelectorModule } from "./../_components/date-range-selector/date-range-selector.module";
import { GcpProfilePhotoModule } from "./../_components/gcp-profile-photo/gcp-profile-photo.module";
import { MainSidebarModule } from "./../_components/main-sidebar/main-sidebar.module";
import { AppHeightDirectiveModule } from "./../_directives/appHeight/appHeight.module";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./home.component";
import { MainToolbarModule } from "../_components/main-toolbar/main-toolbar.module";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,

    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,

    AppHeightDirectiveModule,
    MainToolbarModule,
    MainSidebarModule,
    GcpProfilePhotoModule,
    DateRangeSelectorModule,
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent],
})
export class HomeModule {}
