import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSharingService } from 'src/app/_services/data.sharing.service';
import { ApiService } from './../_services/api.service';
import { AuthtokenService } from './../_services/authtoken.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-home-component',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.scss'
    ],
})
export class HomeComponent implements OnInit {
    @ViewChild('toolBar', { static: true }) child;
    authToken: string;
    loading: boolean = false;

    userProfile: any = {};
    params = ['orders', 'sales', 'visits'];
    length = 0;
    pageSize = 50;
    notifications: any[] = [];
    url: string;

    osData: any;
    graphWidth = 0;
    strikeRate: number = 0;

    visitStats: any;
    orderStats: any;
    saleStats: any;
    userStats: any;
    outletStats: any;
    assetStats: any;

    search: any = {
        startDate: '',
        endDate: ''
    };

    apps: any;

    constructor(
        private router: Router,
        private titleService: Title,
        private tokenService: AuthtokenService,
        private dataSharingService: DataSharingService,
        private apiService: ApiService,
        private snackbar: MatSnackBar,
    ) {
        this.tokenService.currentToken.subscribe(token => {
            if (token) {
                this.authToken = token;
            }
        });

        this.dataSharingService.currentUserProfile.subscribe(res => {
            this.userProfile = res;
            if (this.userProfile && this.userProfile.account) {
                this.titleService.setTitle(this.userProfile.account.name)
                this.determineName();
            }
        });

        this.tokenService.currentApps.subscribe(resp => {
            if (resp) {
                this.apps = resp;
            }
        });
    }

    ngOnInit() {
        this.loadNotifications();
        this.getProfilePhoto();
    }

    loadData() {
        if (this.search.startDate && this.search.endDate) {
            this.getVisitStats();
            this.getTradeSummary();
            this.getUserStats();
            this.getBusinessStats();
            this.getAssetStats();
        } else {
            this.snackbar.open('Select values for both menu and interval', 'INFO', { duration: 2000 });
        }
    }

    determineName() {
        if (this.userProfile.name != null) {
            var splitted = this.userProfile.name.split(" ", 3);
            if (splitted[0].length < 3) {
                this.userProfile.name = splitted[1] ? splitted[0] + ' ' + splitted[1] : splitted[0];
            } else {
                this.userProfile.name = splitted[0];
            }
        }
    }

    loadNotifications(page?: number) {
        this.apiService.genericGET(this.authToken, 'notifications/list', { page: page ? page : 0 }).subscribe(res => {
            this.notifications = res.content;
            console.log(this.notifications);
            this.length = res.totalElements;
        }, () => {
            this.snackbar.open('Could not load list of notifications', 'ERROR', { duration: 2000 });
        });
    }

    pageEvent(e: any) {
        this.loadNotifications(e.pageIndex);
    }

    markAsRead(id: string) {
        this.apiService.genericPOSTOrPUT('post', this.authToken, 'notifications/list', {
            id: id,
            closed: true
        }).subscribe(() => {
            this.snackbar.open('Notification updated', 'SUCCESS', { duration: 2000 });
            this.dataSharingService.changeNotification({ key: 'Whatever' });
            this.loadNotifications(0);
        });
    }

    openNotificationUrl(notification: any) {
        if (notification.url) {
            this.router.navigate([notification.url]);
        }
    }

    delete(id: string) {
        this.apiService.genericDELETE(this.authToken, 'notifications/' + id).subscribe(() => {
            this.snackbar.open('Notification deleted', 'SUCCESS', { duration: 2000 });
            this.dataSharingService.changeNotification({ key: 'Whatever' });
            this.loadNotifications(0);
        });
    }

    getProfilePhoto() {
        this.apiService.genericGET(this.authToken, 'user-accounts/profile/url').subscribe(res => {
            this.url = res.url
        }, () => {
            this.snackbar.open('Could not load user photo', 'ERROR', { duration: 2000 });
        });
    }

    getVisitStats() {
        if (this.search.startDate && this.search.endDate) {
            this.apiService.genericGET(
                this.authToken, 'analytics/visits/dashboard-stats', this.search,
            ).subscribe(res => {
                this.visitStats = res;
            });
        }
    }

    getBusinessStats() {
        this.apiService.genericGET(
            this.authToken, 'analytics/organization-business-stats', this.search,
        ).subscribe(res => {
            this.outletStats = res;
            this.calculateStrikeRate();
        });
    }

    getUserStats() {
        this.apiService.genericGET(this.authToken, 'analytics/organization-user-stats').subscribe(res => {
            this.userStats = res;
        });
    }

    getAssetStats() {
        this.apiService.genericGET(this.authToken, 'analytics/organization-asset-stats').subscribe(res => {
            this.assetStats = res;
        });
    }

    getTradeSummary() {
        this.apiService.genericGET(this.authToken, 'analytics/dashboard-summary', this.search).subscribe(resp => {
            this.orderStats = resp[0];
            this.saleStats = resp[1];
        });

    }

    dateRangeListener(data: any) {
        if (data.start) {
            this.search.startDate = data.start;
        }
        if (data.end) {
            this.search.endDate = data.end;
        }
        this.loadData();
    }

    calculateStrikeRate() {
        if (this.outletStats) {
            this.strikeRate = this.outletStats?.strikeActive / this.outletStats?.visited;
        }
    }

    beautify = n => ((Math.log10(n) / 3 | 0) == 0) ? n : Number((n / Math.pow(10, (Math.log10(n) / 3 | 0) * 3)).toFixed(1)) + ["", "K", "M", "B", "T",][Math.log10(n) / 3 | 0];
}
