import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DateRangeSelectorComponent } from './date-range-selector.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule
    ],
    declarations: [
        DateRangeSelectorComponent
    ],
    exports: [
        DateRangeSelectorComponent,
    ]
}) export class DateRangeSelectorModule {

}
