import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Directive({
    selector: '[appHeight]',
})
export class AppHeightDirective implements OnInit, OnDestroy {
    @Input() appHeight: number;

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;

    constructor(
        private el: ElementRef,
    ) { }

    ngOnInit() {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
            this.el.nativeElement.style.height = (window.innerHeight - this.appHeight) + 'px';
        });
        this.el.nativeElement.style.height = (window.innerHeight - this.appHeight) + 'px';
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe();
    }
}
