<div class="app-parent-container">

    <app-main-sidebar-component #toolBar></app-main-sidebar-component>

    <div class="app-container" [appHeight]="170">
        <app-main-toolbar-component></app-main-toolbar-component>
        <div class="container">

            <div class="row">
                <div class="col-8">
                    <br>
                    <div class="row container">

                        <div class="titles">
                            Hello {{userProfile?.name}}
                        </div>
                        <span class="my-spacer"></span>

                        <div class="profile-pic mat-elevation-z2">
                            <app-gcp-profile-photo-component class="img-circle" [style.position]="'absolute'" [width]="100" [height]="100" [url]="url"></app-gcp-profile-photo-component>
                        </div>

                    </div>

                    <div class="filter-container ">
                        <h5 class="subtitle">My highlights</h5>
                        <span class="my-spacer"></span>
                        <app-date-range-selector (selectionMade)="dateRangeListener($event)"></app-date-range-selector>

                    </div>


                    <div class="dash-div">

                    </div>
                    <br>

                    <div class="stats-container">
                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('SALES')">
                            <p class="card-title  bordered-bottom">TOTAL SALES</p>
                            <span class="large-value">{{(saleStats?.msum ? saleStats?.msum : 0 ) | currency: 'KES'}}</span>
                            <div class="row vertical-flex">
                                <span class="small-value">{{(saleStats?.mavg ? saleStats?.mavg : 0) | currency: 'KES'}}</span>
                                <span class="small-header">Avg sale value</span>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('ORDERS')">
                            <p class="card-title  bordered-bottom">TOTAL ORDERS</p>

                            <span class="large-value">{{(orderStats?.msum ? orderStats?.msum : 0)| currency: 'KES'}}</span>

                            <div class="row vertical-flex">
                                <span class="small-value">{{(orderStats?.mavg ? orderStats?.mavg : 0) | currency: 'KES'}}</span>
                                <span class="small-header">Avg order value</span>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('BUSINESSES')">
                            <p class="card-title  bordered-bottom">BUSINESSES</p>
                            <span class="large-value"> {{outletStats?.total ?outletStats?.total : 0}} total</span>

                            <div class="row vertical-flex ">
                                <span class="small-value yellow-text">{{this.outletStats?.active}}</span>
                                <span class="small-header">active</span>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('USERS')">
                            <p class="card-title bordered-bottom">USERS</p>

                            <span class="large-value">{{userStats?.count ? userStats?.count : 0}} total</span>

                            <div class="row vertical-flex ">
                                <span class="small-value yellow-text">{{userStats?.disabled ? userStats?.disabled : 0}}</span>
                                <span class="small-header">Disabled</span>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('BUSINESSES')">
                            <p class="card-title bordered-bottom">ASSETS</p>

                            <span class="large-value">{{assetStats?.count ? assetStats?.count : 0}} total</span>

                            <div class="row vertical-flex ">
                                <span class="small-value yellow-text">{{assetStats?.pendingTransfers ? assetStats?.pendingTransfers : 0}}</span>
                                <span class="small-header">Open transfers</span>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('ORDERS')">
                            <p class="card-title bordered-bottom">ORDER STATUS</p>

                            <div class="order-status">
                                <div class="status-row green-text">
                                    <span class="count ">{{orderStats?.opened ? orderStats?.opened : 0 }}</span><span class="status">Open</span>
                                </div>
                                <div class="status-row green-text">
                                    <span class="count ">{{orderStats?.processing ? orderStats?.processing : 0 }}</span><span class="status">Processing</span>
                                </div>
                                <div class="status-row green-text">
                                    <span class="count">{{orderStats?.dispatched ? orderStats?.dispatched : 0 }}</span><span class="status">Dispatched</span>
                                </div>
                                <div class="status-row teal-text">
                                    <span class="count">{{orderStats?.delivered ? orderStats?.delivered : 0 }}</span><span class="status">Delivered</span>
                                </div>
                                <div class="status-row teal-text">
                                    <span class="count ">{{orderStats?.closed ? orderStats?.closed : 0 }}</span><span class="status">Closed</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngif="apps.includes('VISITS')">
                            <p class="card-title bordered-bottom">VISITS</p>

                            <div class="status-row">
                                <span class="small-value">{{visitStats?.visitsPlanned ? visitStats?.visitsPlanned : 0}} </span>
                                <span class="status">visits planned</span>
                            </div>
                            <div class="status-row">
                                <span class="small-value">{{visitStats?.visitsClosed ? visitStats?.visitsClosed : 0}} </span>
                                <span class="status">visits closed</span>
                            </div>
                            <div class="status-row">
                                <span class="small-value">{{visitStats?.tasksCompleted ? visitStats?.tasksCompleted : 0}} </span>
                                <span class="status">tasks completed</span>
                            </div>
                            <div class="status-row">
                                <span class="small-value">{{visitStats?.businessesVisited ? visitStats?.businessesVisited : 0}} </span>
                                <span class="status">businesses visited</span>
                            </div>
                        </div>

                        <div class="col-sm stat mat-elevation-z2 margin-r10" *ngIf="apps.includes('VISITS')">
                            <p class="card-title bordered-bottom">Highlights</p>

                            <div class="status-row">
                                <span class="small-value">{{(visitStats?.visitsPlanned ? visitStats?.visitsCoverage : 0) | number:'.1-2'}} % </span>
                                <span class="status">visits coverage</span>
                            </div>
                            <div class="status-row">
                                <span class="small-value">{{(visitStats?.outletsCoverage ? visitStats?.outletsCoverage : 0) | number:'.1-2' }} % </span>
                                <span class="status">businesses coverage</span>
                            </div>
                            <div class="status-row">
                                <span class="small-value">{{strikeRate | percent:'.1-2'}} </span>
                                <span class="status">strike rate</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4 right-pane">
                    <div class="notifications-table" [appHeight]="140">
                        <h4 class="title">notifications</h4>
                        <ng-container *ngIf="apps.includes('ORDERS') || apps.includes('LEADS')">
                            <div [class.inactive]="nots.closed" class="notification mat-elevation-z2" *ngFor="let nots of notifications" (click)="openNotificationUrl(nots)">
                                <h6>
                                    {{nots.description}}
                                </h6>

                                <div class="notification-footer">
                                    <span class="date">{{nots.createdAt | date:'medium'}}</span>
                                    <span class="my-spacer"></span>
                                    <mat-icon class="edit-icon" *ngIf="!nots.closed" (click)="markAsRead(nots.id);$event.stopPropagation()">check</mat-icon>
                                    <mat-icon class="edit-icon" (click)="delete(nots.id);$event.stopPropagation()">clear</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <br>
                    <mat-paginator [length]="length" [pageSize]="pageSize" [hidePageSize]="true" (page)="pageEvent($event)">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>