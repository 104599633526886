<div class="dates-selector">
    <div class="m-form-field">
        <input matInput readonly [matDatepicker]="dp1" [min]="minAnchorDate" [max]="today" [formControl]="fromDate" placeholder="From">
        <mat-datepicker #dp1></mat-datepicker>
        <mat-icon (click)="dp1.open()">today</mat-icon>
    </div>

    <div class="m-form-field">
        <input matInput readonly [matDatepicker]="dp2" [min]="minAnchorDate" [formControl]="toDate" placeholder="To">
        <mat-datepicker #dp2></mat-datepicker>
        <mat-icon (click)="dp2.open()">today</mat-icon>
    </div>
</div>