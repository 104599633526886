<mat-toolbar class="custom-toolbar">
    <span class="my-spacer"></span>

    <mat-icon class="main-content-menu-icon" [matMenuTriggerFor]="menu">account_circle</mat-icon>

    <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before">
        <div mat-menu-item (click)="myProfile()">
            <mat-icon>account_circle</mat-icon>
            <span>My profile</span>
        </div>
        <div mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Log out</span>
        </div>
    </mat-menu>

</mat-toolbar>