import { AppConstants } from './../../_constants/app.constants';
import { AuthtokenService } from 'src/app/_services/authtoken.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { DataSharingService } from 'src/app/_services/data.sharing.service';

@Component({
    selector: 'app-main-sidebar-component',
    templateUrl: './main-sidebar.component.html',
    styleUrls: [
        './main-sidebar.component.css'
    ]
}) export class MainSidebarComponent implements OnInit {

    apps: any[] = [
        { icon: 'home', name: 'Home', link: '/home' },
    ];

    authToken: string;
    url: any;
    active: string;

    constructor(
        private router: Router,
        private tokenService: AuthtokenService,
        private apiService: ApiService,
        private dataSharingService: DataSharingService
    ) {
        this.tokenService.currentToken.subscribe(token => {
            if (token) {
                this.authToken = token;
            }
        });
        this.tokenService.currentApps.subscribe(resp => {
            if (resp) {
                AppConstants.APPS.forEach(element => {
                    if (resp.includes(element.name.toUpperCase())) {
                        this.apps.push(element);
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.apiService.genericGET(this.authToken, 'user-accounts/profile').subscribe(resp => {
            this.dataSharingService.changeUserProfile(resp);
            this.tokenService.setCurrency(resp.organization.organizationSettings.currency);
            this.url = 'https://storage.googleapis.com/actisales-public/logos/' + resp.organization.id + '.png';
        });

        this.active = '/' + this.router.url.split('/')[1];
    }

    changeApp(data: any){
        this.router.navigate([data.link]);
    }
}
