
export class AppConstants {
    // public static API_HOST_NAME = 'http://localhost:8080/api' ;
    public static API_HOST_NAME = "https://actisales.angalabapis.com/api";
    public static S3_HOST_NAME = 'https://zrt339wrmj.execute-api.eu-west-2.amazonaws.com/dev';
    public static LAMBDA_GW_HOST_NAME = 'https://3f4w5dtx5m.execute-api.eu-west-2.amazonaws.com/dev';

    public static APPS = [
        { icon: 'dashboard', name: 'Dashboards', link: '/dashboards' },
        { icon: 'group_add', name: 'Leads', link: '/leads' },
        { icon: 'shopping_cart', name: 'Orders', link: '/orders' },
        { icon: 'check_circle', name: 'Sales', link: '/sales' },
        { icon: 'directions', name: 'Visits', link: '/visits' },
        // { icon: 'storefront', name: 'Outlets', link: '/outlets' },
        { icon: 'trending_up', name: 'Analytics', link: '/analytics' },
        { icon: 'supervised_user_circle', name: 'Users', link: '/users' },
        { icon: 'store', name: 'Businesses', link: '/businesses' },
        { icon: 'gite', name: 'Warehousing', link: '/warehousing' },
        { icon: 'article', name: 'Reports', link: '/reports' },
        { icon: 'settings_applications', name: 'Settings', link: '/settings' },
    ];

    public static CHARTS = [
        { icon: 'show_chart', name: 'LINE', title: 'Line chart' },
        { icon: 'donut_large', name: 'DONUT', title: 'Donut chart' },
        { icon: 'bar_chart', name: 'BAR', title: 'Bar chart' },
        { icon: 'stacked_bar_chart', name: 'STACKED_BAR', title: 'Stacked bar chart' },
    ];

    public static D3_JS_CHART_HEIGHT = 300;
    public static DASH_CHART_HEIGHT = 400;


    public static VISIT_STATUS = ['OPEN', 'CLOSED'];
    public static CONTENT_FILTERS = ['ALL ITEMS', 'EXCLUDE NULL ITEMS'];
    public static ITEM_TYPES = ['TEXT', 'NUMBER', 'SELECTION', 'COMPOUND', 'BOOLEAN', 'DATE', 'PHOTO', 'BARCODE', 'AUDIO', 'VIDEO'];

    public static WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    public static COLORS = ['#6a8caf', '#75b79e', '#a7e9af', '#eef9bf', '#d15a7c', '#ee8972', '#f7d695', '#f6eec7', '#fddb3a', '#d5c455'];

    public static SUPPORTED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'pdf'];

    public static EXTENSIONS_MAPPING = {
        jpg: 'image/jpg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf'
    };

    public static ORDER_STATUS = ['OPEN', 'PROCESSING', 'DISPATCHED', 'DELIVERED', 'CLOSED'];

    public static getOrderStatuses(current: string) {
        switch (current) {
            case this.ORDER_STATUS[0]:
                return [this.ORDER_STATUS[0], this.ORDER_STATUS[1], this.ORDER_STATUS[2]];
            case this.ORDER_STATUS[1]:
                return [this.ORDER_STATUS[1], this.ORDER_STATUS[2], this.ORDER_STATUS[4]];
            case this.ORDER_STATUS[2]:
                return [this.ORDER_STATUS[2], this.ORDER_STATUS[4]];
            case this.ORDER_STATUS[3]:
                return [this.ORDER_STATUS[3], this.ORDER_STATUS[4]];
            case this.ORDER_STATUS[4]:
                return [this.ORDER_STATUS[4]];
        }
    }

    public static refactorObject = (objct: any) => {
        const tempObject: any = [];
        // tslint:disable-next-line:prefer-const
        for (let prop in objct) {
            if (objct.hasOwnProperty(prop)) {
                tempObject.push({ name: prop, value: objct[prop] });
            }
        }
        return tempObject;
    }

    public static outletHtmlStyle = `
        background-color: #339933;
        width: 2rem;
        height: 2rem;
        display: block;
        left: -1rem;
        top: -1rem;
        position: relative;
        border-radius: 2rem 2rem 0;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF`

    public static pointHtmlStyle = `
        background-color: #859fbf;
        width: 1rem;
        height: 1rem;
        display: block;
        left: -1rem;
        top: -1rem;
        position: relative;
        border-radius: 2rem 2rem 0;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF`

    public static checkinHtmlStyle = `
        background-color: #ff1414;
        width: 2rem;
        height: 2rem;
        display: block;
        left: -1rem;
        top: -1rem;
        position: relative;
        border-radius: 2rem 2rem 0;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF`

    public static checkoutHtmlStyle = `
        background-color: #0080ff;
        width: 2rem;
        height: 2rem;
        display: block;
        left: -1rem;
        top: -1rem;
        position: relative;
        border-radius: 2rem 2rem 0;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF`

}
