import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { UserProfileComponent } from "./user_profile.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [CommonModule, MatDialogModule, MatIconModule],
    declarations: [UserProfileComponent],
    providers: [],
    exports: [UserProfileComponent]
})
export class UserProfileModule {}
